// 3rd
import Axios from 'axios';
import { getAppSession } from '@frontegg/nextjs/app';

// App - Other
import { API_URL } from '@/config/constants';

export const apiClient = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    if (!config.headers['Authorization']) {
      const { accessToken } = (await getAppSession()) || {};

      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Skip showing error notification for 4xx errors
    if (error?.response?.status < 500) return Promise.reject(error);

    // TODO commented as Or asked to not show random popups when something "off" happens
    // const message = error.response?.data?.message || error.message;
    //
    // notificationsStore.getState().showNotification({
    //   type: 'error',
    //   title: 'Error',
    //   duration: 5000,
    //   message,
    // });

    return Promise.reject(error);
  }
);
